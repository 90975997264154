import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import fbc from "../images/bcFirstDivider.png"
import sbc from "../images/bcSecondDivider.png"
import beerDecor from '../images/beerDetail/beerDetailDecor.png'
import beerDecorWhite from '../images/beerDetail/beerDetailDecorWhite.png'
import goBack from '../images/beerDetail/goBack.png'
import bottle1 from '../images/beerDetail/bottle1.png'
import bottle2 from '../images/beerDetail/bottle2.png'
import bottle3 from '../images/beerDetail/bottle3.png'
import bottle4 from '../images/beerDetail/bottle4.png'
import amberlager from '../images/beerImages/Amber.jpg'
import bohemian from '../images/beerImages/Bohemian.jpg'
import fipa from '../images/beerImages/Fipa.jpg'
import KM from '../images/beerImages/KM.jpg'
import kune from '../images/beerImages/Kune.jpg'
import porter from '../images/beerImages/Porter.jpg'
import octubrefest from '../images/beerImages/Octubrefest.jpg'
import weisse from '../images/beerImages/Weisse.jpg'
import ado from '../images/beerImages/ADO.jpg'
import hoppylagger from '../images/beerImages/HL.jpg'
import veraipa from '../images/beerImages/VL.jpg'
import Cookies from "js-cookie"
import SEO from "../components/seo"
import Header from "../components/header"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"
import BirthConsent from "../components/BirthConsent"
import HeaderHL from "../components/HeaderHL"
import lupuloMenu from '../images/lupulo-verticalmenu.png'

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};


export default class DetailHL extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      amberlager: amberlager,
      bohemianpilsener: bohemian,
      fipa: fipa,
      km: KM,
      kune: kune,
      porter: porter,
      octubrefest: octubrefest,
      weisse: weisse,
      abrazodeoso: ado,
      hoppylagger: hoppylagger,
      veraipa: veraipa,
      isScrolling: false,
      menuOpen: false,
      menuClass: 'slideOut',
      isDark: true,
      width: 1000,
      birthConsent: true,
    }
  }

  componentDidMount() {
    const beers = this.props.data.allSitePage.edges

    // console.log(beers);
    let arr = []
    beers.forEach(function(beerNode) {
      arr.push(beerNode.node);
    })
    function compare( a, b ) {
      if ( a.title < b.title ){
        return -1;
      }
      if ( a.title > b.title ){
        return 1;
      }
      return 0;
    }
    arr.sort(compare);
    this.setState({data: arr})
    
    window.addEventListener("scroll", this.onScroll);
    this.setState({
      width: window.innerWidth,
    })
    if (hasConfirmed()){
      this.handleConsent()
    }
    else {
      this.setState({
        birthConsent: false,
      })
    }
  }
  handleConsent = () =>{
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onMenuToggle = () =>{
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
      isDark: !this.state.menuOpen
    })
  };

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 200);

    var section = document.getElementsByTagName("SECTION");



    var bScroll = document.scrollingElement.scrollTop;

    for (var i = 0; i < section.length; i++) {

      var sHeight = section[i].offsetHeight;
      var offsets = section[i].offsetTop;


      if (bScroll > offsets && bScroll < offsets + sHeight) {
        console.log(section[i].className)
        if (section[i].className !== 'holaHome'){
          this.setState({ isDark: true });
        }
        else if (section[i].className === 'holaHome') {
          this.setState({ isDark: false });
        }
      }/* else {
        section[i].className = "section";
      }*/


    }

  };
  render() {
    const beer = this.props.data.allSitePage.edges[0].node.context
    return (
      <div>
        <SEO title="Nuestras Cervezas" />
        {this.state.birthConsent &&
        <div>
          <Header isScrolling={this.state.isScrolling} onMenuToggle={this.onMenuToggle} isDark={this.state.isDark}
                  menuOpen={this.state.menuOpen} width={this.state.width}/>
          <Link to={'/nuestrascervezas'} className={'goBackDesktop'}>
            <img  alt="" src={goBack} className={'goBack'}/>
          </Link>
          <div className={'breadcrumbsContainer breadcrumbsDetailBeer'}>
            <div className={'breadcrumbsWrapper'}>

              <span className={'bcSection'}>{beer.title}</span>
              <img  alt="" className={'fbc'} src={fbc}/>
              <span className={'bcPage'}>cervezas</span>
              <img  alt="" className={'sbc'} src={sbc}/>
              <img  alt="" className={'bcLupulo'} src={lupuloMenu}/>
            </div>
          </div>
          <img  alt="" className={'beerDetailsImage'} src={beer.image}/>
          <div className={'beerDetailContent'}>
            <div className={'beerDetailsContainer'}>
              <div className={'beerDetailsTitle'}>{beer.title}</div>
              <div className={'beerMobileExtraData'}>
                <img  alt="" className={'beerDecor'} src={beerDecor}/>
                <div className={'beerExtraData'}>{beer.abv + '% ABV'}</div>
                <div className={'beerExtraData'}>{beer.ibu + ' IBU'}</div>
              </div>
              <div className={'beerDetails'}>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    APARIENCIA
                  </div>
                  <div className={'beerDetailItemData'}>{beer.apariencia}</div>
                </div>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    AROMA
                  </div>
                  <div className={'beerDetailItemData'}>{beer.aroma}</div>
                </div>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    SABOR
                  </div>
                  <div className={'beerDetailItemData'}>{beer.sabor}</div>
                </div>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature halfFeature'}>
                    TEMPERATURA
                  </div>
                  <div className={'beerDetailItemData halfData'}>{beer.temperatura}</div>
                  <div className={'beerDetailItemData halfData halfData2'}>{beer.vaso}</div>
                  <div style={{marginRight: 20}} className={'beerDetailItemFeature halfFeature halfData2'}>
                    VASO IDEAL
                  </div>

                </div>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    MALTAS USADAS
                  </div>
                  <div className={'beerDetailItemData'}>{beer.maltas}</div>
                </div>

                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    Lúpulos usados
                  </div>
                  <div className={'beerDetailItemData'}>{beer.lupulos}</div>
                </div>
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    Maridaje ideal
                  </div>
                  <div className={'beerDetailItemData'}>{beer.maridaje}</div>
                </div>
                {beer.extra &&
                <div className={'beerDetailItem'}>
                  <div className={'beerDetailItemFeature'}>
                    dato de color
                  </div>
                  <div className={'beerDetailItemData'}>{beer.extra}</div>
                </div>
                }
              </div>
              <div className={'beerBuyDownload'}>
                <a href={`https://www.craftsociety.com.ar/products/cerveza-patagonia-hoppy-lager-lata-269ml`} target="_blank" rel="noreferrer" className={'beerBuy'}>
                  COMPRAR
                </a>
                <div className={'beerDownload'}>
                  descargar ficha
                </div>
                <Link to={'/nuestrascervezas'} className={'goBackMobile'}>
                  <img  alt="" src={goBack} className={'goBack'}/>
                </Link>
              </div>
            </div>
            <div className={'beerDetailsImageContainer'}
                 style={{backgroundImage: 'url('+beer.image+')'}}>

              <div className={'extraDetailsBox'}>
                <div className={'beerDecorContainer'}>
                  <div className={'beerDecorWrap'} style={{display: 'inline-block'}}>
                    <img  alt="" src={beerDecorWhite} className={'beerDecorWhite'}/>
                  </div>
                </div>

                <div className={'ibuabv'}>
                  <div className={'abv'}>{beer.abv + '% ABV'}</div>
                  <div className={'ibu'}>{beer.ibu + ' IBU'}</div>
                </div>
                <div className={'extraDescription'}>
                  {beer.description}
                </div>
              </div>
              <div className={'calibreContainer'}>
                <img  alt="" src={bottle1} className={'bottle1'}/>
                <div className={'calibreText'}>{beer.calibre1}</div>
                {/*<img  alt="" src={bottle2} className={'bottle2'}/>
                <div className={'calibreText'}>{beer.calibre2}</div>*/}
                <img  alt="" src={bottle3} className={'bottle3'}/>
                <div className={'calibreText'}>{beer.calibre3}</div>
                <img  alt="" src={bottle4} className={'bottle4'}/>
                <div className={'calibreText'}>{beer.calibre4}</div>
              </div>
            </div>
          </div>
          {
            this.state.width > 767 ?
              <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
              :
              <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
          }
          <Footer/>
        </div>
        }
        {!this.state.birthConsent &&
        <BirthConsent handleConsent={this.handleConsent}/>
        }
      </div>

    )
  }
}

export const query = graphql`
query($path: String!)
{
  allSitePage(filter: { path: { eq: $path }}) {
    edges {
     node {
       context{
         route
         title
         apariencia
         aroma
         sabor
         temperatura
         vaso
         maltas
         lupulos
         maridaje
         extra
         image
         menuImage
         description
         abv
         ibu
         calibre1
         calibre2
         calibre3
         calibre4
         pdf
         variedad
         stock
         urlCraft
        }
      }
    }
  }
}`